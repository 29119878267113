// app/javascript/controllers/health_record_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "select" ]
  connect() {
    console.log("is it connecting ?");
    this.element.addEventListener("turbo:submit-end", (event) => {
      // debugger;
      this.next(event)
    })

    // debugger;
    // const selectElement = document.querySelector("#health-type-select");
    // const result = document.querySelector(".result");

    // selectElement.addEventListener("change", (event) => {
    //   result.textContent = `You like ${event.target.value}`;
    //   // this.loadTemplate(event);
    // });

    //  this.element.textContent = "Hello World!"
  }

  next(event) {
    // debugger;
    if (event.detail.success) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }

  change(event) {
    console.log("Change");
    // debugger;
    // var label_id = document.getElementById("label_id").value;
    // var jpeg_id = document.getElementById("jpeg_id").value;
    // const frame = document.getElementById('image_objects');
    // frame.src="update_label_content?jpeg_id=" + jpeg_id + "&id=" + label_id;
    // frame.reload();
  }

  loadTemplate(event) {
    console.log("yeah");
    const selectElement = event.target;
    const healthType = selectElement.value;
    const turboFrame = document.querySelector("turbo-frame#new_health_record");

    const url = `/health_records/new?health_record[record_type]=${healthType}`;
    // debugger;
    turboFrame.src = url;  // Trigger Turbo Frame to load the new content
    // frame.src=event.target.value;
    turboFrame.reload();
  }
}

